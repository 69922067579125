import * as Yup from 'yup'
import {IBudgetForecast} from './BudgetForecast'

export const validationEditSchema = Yup.object().shape({
  name: Yup.string().required('PLATFORM.VALID.NAME_REQ'),
})

export const initialValues: IBudgetForecast = {
  id: undefined,
  industry: '',
  channel: '',
  cpc: 0,
  ctr: 0,
  conversion_rate: 0,
  conversion_value: 0,
  conversion_number: 0,
  cart_value: 0,
  active: true,
}
