import axios from 'axios'
import {ISetting} from '../models/Setting'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const SETTING_API = `${API_URL}/settings`

export async function getSettings(page: number, perPage: number, filter: string, search?: string) {
  if (search)
    return await axios.get(
      `${SETTING_API}?search=${search}&page=${page}&per-page=${perPage}&${filter}`
    )
  return await axios.get(`${SETTING_API}?page=${page}&per-page=${perPage}&${filter}`)
}

export async function getSettingById(settingId: number) {
  return await axios.get(`${SETTING_API}/${settingId}`)
}

export async function deleteSetting(settingId: number) {
  return await axios.delete(`${SETTING_API}/${settingId}`)
}

export async function updateSetting(updateSetting: ISetting) {
  return await axios
    .put(`${SETTING_API}/${updateSetting.id}`, updateSetting)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}

export async function createSetting(newSetting: ISetting) {
  return await axios
    .post(`${SETTING_API}`, newSetting)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}
