import {createContext, useState} from 'react'
import {Alert} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useSpring, animated} from 'react-spring'

interface AlertContextProps {
  isAlertShow: boolean
  showAlert: (alertText: string[][], color: string, show: boolean) => void
  hideAlert: () => void
}

const defaultState: AlertContextProps = {
  isAlertShow: false,
  showAlert: () => {},
  hideAlert: () => {},
}

export const AlertContext = createContext<AlertContextProps>(defaultState)

const AlertProvider: React.FC = ({children}) => {
  const intl = useIntl()
  const [alertColor, setAlertColor] = useState<string>('success')
  const [alertText, setAlerttext] = useState<string[][]>([[]])
  const [isAlertShow, setIsAlertShow] = useState<boolean>(defaultState.isAlertShow)

  const showAlert = (alertText: string[][], color: string, show: boolean) => {
    setAlerttext(alertText)
    setAlertColor(color)
    setIsAlertShow(show)
    setTimeout(() => setIsAlertShow(false), 5000)
  }

  const {xyz} = useSpring({
    xyz: isAlertShow ? [0, 0, 0] : [1920, 0, 0],
  })

  const hideAlert = () => {
    setIsAlertShow(false)
  }

  return (
    <AlertContext.Provider value={{isAlertShow, showAlert, hideAlert}}>
      {children}
      <>
        <animated.div
          className='alert-block'
          style={{
            transform: xyz.to((x, y, z) => `translate3d(${x}px, ${y}px, ${z}px)`),
          }}
        >
          <Alert
            className='px-10'
            variant={alertColor}
            onClose={() => setIsAlertShow(false)}
            dismissible
          >
            {alertColor === 'success' && (
              <Alert.Heading className='mb-0 px-10'>
                {intl.formatMessage({id: 'OFFER.CREATE.ALERT.SUCCESS'})}
              </Alert.Heading>
            )}
            {alertColor === 'danger' && (
              <Alert.Heading className=''>
                {intl.formatMessage({id: 'USER.ALERT.FAIL'})}
              </Alert.Heading>
            )}
            {alertText.map((item, index) => (
              <div key={index} className='mb-2'>
                {item}
              </div>
            ))}
          </Alert>
        </animated.div>
      </>
    </AlertContext.Provider>
  )
}

export default AlertProvider
