import React, {useCallback, useState} from 'react'
import {IBudgetForecast} from '../models/BudgetForecast'
import {Button} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {DeleteBudgetForecastModal} from './DeleteBudgetForecastModal'
import {EditBudgetForecastModal} from './EditBudgetForecastModal'

interface BudgetForecastListItemProps {
  budgetForecast: IBudgetForecast
}

const BudgetForecastListItem: React.FC<BudgetForecastListItemProps> = ({budgetForecast}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)

  const handleCloseDelete = useCallback(() => {
    setShowDeleteModal(false)
  }, [])

  const handleShowDelete = useCallback(() => {
    setShowDeleteModal(true)
  }, [])

  const handleCloseEdit = () => setShowEditModal(false)
  const handleShowEdit = () => setShowEditModal(true)

  return (
    <>
      <tr>
        <td>{budgetForecast.industry}</td>
        <td>{budgetForecast.channel}</td>
        <td>{budgetForecast.cpc}</td>
        <td>{budgetForecast.ctr}</td>
        <td>{budgetForecast.conversion_rate}</td>
        <td>{budgetForecast.conversion_value}</td>
        <td>{budgetForecast.conversion_number}</td>
        <td>{budgetForecast.cart_value}</td>
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <Button
              className='btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-1'
              variant='light'
              onClick={handleShowEdit}
            >
              <KTSVG path='/media/icons/duotone/Communication/Write.svg' className='svg-icon-3' />
            </Button>
            <Button
              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
              variant='light'
              onClick={handleShowDelete}
            >
              <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
            </Button>
          </div>
        </td>
      </tr>
      <DeleteBudgetForecastModal {...{showDeleteModal, handleCloseDelete, budgetForecast}} />
      <EditBudgetForecastModal {...{showEditModal, handleCloseEdit, budgetForecast}} />
    </>
  )
}

export default BudgetForecastListItem
