import axios from 'axios'
import {INegativeKeyword} from '../models/NegativeKeyword'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const NEGATIVE_KEYWORD_API = `${API_URL}/negativeKeywords`

export async function getNegativeKeywordsList(
  page: number,
  perPage: number,
  filter: string,
  search?: string
) {
  if (search)
    return await axios.get(
      `${NEGATIVE_KEYWORD_API}?search=${search}&page=${page}&per-page=${perPage}&${filter}`
    )
  return await axios.get(`${NEGATIVE_KEYWORD_API}?page=${page}&per-page=${perPage}&${filter}`)
}

export async function getNegativeKeywordById(negativeKeywordId: number) {
  return await axios.get(`${NEGATIVE_KEYWORD_API}/${negativeKeywordId}`)
}

export async function deleteNegativeKeyword(negativeKeywordId: number) {
  return await axios.delete(`${NEGATIVE_KEYWORD_API}/${negativeKeywordId}`)
}

export async function updateNegativeKeyword(updateNegativeKeyword: INegativeKeyword) {
  return await axios
    .put(`${NEGATIVE_KEYWORD_API}/${updateNegativeKeyword.id}`, updateNegativeKeyword)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}

export async function createNegativeKeyword(newNegativeKeyword: INegativeKeyword) {
  return await axios
    .post(`${NEGATIVE_KEYWORD_API}`, newNegativeKeyword)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}
