/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useSelector} from 'react-redux'
import {selectors as authSelectors} from '../../../../app/modules/auth'
import {UserRoles} from '../../../../app/modules/users/models/User'

export const AsideMenuMain: React.FC = () => {
  const intl = useIntl()
  const authUser = useSelector(authSelectors.getUser)

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/clients'
        icon='/media/icons/duotone/General/User.svg'
        title={intl.formatMessage({id: 'CLIENTS'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/subscriptions'
        icon='/media/icons/duotone/Home/Mailbox.svg'
        title={intl.formatMessage({id: 'SUBSCRIPTIONS'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/projects'
        icon='/media/icons/duotone/Home/Mailbox.svg'
        title={intl.formatMessage({id: 'PROJECTS'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/document/invoices'
        icon='/media/icons/duotone/Home/Mailbox.svg'
        title={intl.formatMessage({id: 'INVOICES'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Ustawienia SHOPAI
          </span>
        </div>
      </div>
      <AsideMenuItem
        to='/platforms'
        icon='/media/icons/duotone/Home/Mailbox.svg'
        title={intl.formatMessage({id: 'PLATFORMS'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/price_lists'
        icon='/media/icons/duotone/Shopping/Dollar.svg'
        title={intl.formatMessage({id: 'PRICE_LISTS'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/settings'
        icon='/media/icons/duotone/Shopping/Dollar.svg'
        title={intl.formatMessage({id: 'SETTINGS'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/channels'
        icon='/media/icons/duotone/Tools/Axe.svg'
        title={intl.formatMessage({id: 'ADVERTISING_CHANNELS'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/negativeKeywords'
        icon='/media/icons/duotone/Shopping/Barcode-scan.svg'
        title={intl.formatMessage({id: 'NEGATIVE_KEYWORDS'})}
        fontIcon='bi-app-indicator'
      />
      {(authUser?.role === UserRoles.SUPER_ADMIN || authUser?.role === UserRoles.ADMIN) && (
        <AsideMenuItem
          to='/budgetForecasts'
          icon='/media/icons/duotone/Shopping/Barcode-scan.svg'
          title={intl.formatMessage({id: 'BUDGET_FORECASTS'})}
          fontIcon='bi-app-indicator'
        />
      )}
      {authUser?.role !== UserRoles.SPECIALIST && (
        <AsideMenuItem
          to='/discountCodes'
          icon='/media/icons/duotone/Shopping/Barcode-scan.svg'
          title={intl.formatMessage({id: 'DISCOUNT_CODES'})}
          fontIcon='bi-app-indicator'
        />
      )}
      {(authUser?.role === UserRoles.SUPER_ADMIN || authUser?.role === UserRoles.ADMIN) && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin panel</span>
          </div>
        </div>
      )}
      {(authUser?.role === UserRoles.SUPER_ADMIN || authUser?.role === UserRoles.ADMIN) && (
        <AsideMenuItemWithSub
          to='/crafted/accounts'
          title='Accounts'
          icon='/media/icons/duotone/General/User.svg'
          fontIcon='bi-person'
        >
          <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
        </AsideMenuItemWithSub>
      )}
      {(authUser?.role === UserRoles.SUPER_ADMIN || authUser?.role === UserRoles.ADMIN) && (
        <AsideMenuItem
          to='/users'
          icon='/media/icons/duotone/General/User.svg'
          title={intl.formatMessage({id: 'USERS'})}
          fontIcon='bi-app-indicator'
        />
      )}
    </>
  )
}
